<template>
  <div style="margin: 120px auto 240px; padding: 0 12px; width:100%; max-width: 600px; box-sizing: border-box;">
    <h2>About</h2>
    <div class="cred">
      <p>Real Man Fight Club is brought to you by the Real Man Society, who work tirelessly to raise the profile of Real Men around the world (and metaverse).</p>
      <p>All Real Men displayed here take part in the fight club voluntarily, and their welfare is strictly monitored in accordance with the standards laid out in the <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target="_blank">Real Men Wellbeing Protocol 2019</a>.</p>
    </div>
    <h2>Credits</h2>
    <div class="cred">
      <p>All 'A Real Man', pet, or weapon artwork remains property of <a href="https://godsunchained.com/" target="_blank">Gods Unchained</a>.</p>
    </div>
    <div class="cred">
      <p>All code, data wrangling, and awesome animations by Gompy.</p>
    </div>
    <div class="cred">
      <p><strong>Main background music:</strong> Haven by <a href="https://www.serpentsoundstudios.com" target="_blank">Alexander Nakarada (www.serpentsoundstudios.com)</a></p>
      <p><a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">Licensed under Creative Commons BY Attribution 4.0 License</a></p>
    </div>
    <div class="cred">
      <p><strong>Fight sequence background music</strong> via <a href="https://www.FesliyanStudios.com" target="_blank">FesliyanStudios.com</a> and used as per <a href="https://www.fesliyanstudios.com/policy" target="_blank">policy</a>.</p>
    </div>
    <div class="cred">
      <p><strong>Background art for fight sequence:</strong> <a href="https://wallpapersafari.com/w/3Qf14A">WallpaperSafari</a></p>
    </div>
    <h2>Donations</h2>
    <div class="cred">
    <p>Hosting isn't free so please feel free to donate to codeword.eth. All coins/chains accepted (including ETH, GODS, IMX, AVAX-C, MATIC...) or even Real Men cards much appreciated.</p>
    </div>
    <h2>Merch</h2>
    <div class="cred">
      <p>Coming soon... And you'll likely be able to pay with GODS.</p>
    </div>
  </div>
  
</template>

<script>
export default {
  name: 'About',
  props: ['men']
}
</script>

<style scoped>
  .cred{
    margin-bottom: 36px;
  }
</style>